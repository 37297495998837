import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class MedicoService {

  async getMedicosAll() {
    const medicos = await fetchWrapper.get(`${ruta}/medicos`);
    return medicos;
  }

  async sendMedicoNew(datos) {
    const nuevomedico = await fetchWrapper.post(`${ruta}/medicos`, datos);
    return nuevomedico;
  }

  async updateMedico(datos) {
    const medicoActualizado = await fetchWrapper.put(
      `${ruta}/medicos/` + datos.id,
      datos
    );
    return medicoActualizado;
  }

  async deleteMedico(id) {
    return await fetchWrapper.delete(`${ruta}/medicos/${id}`);
  }
}